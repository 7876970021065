import React, { Component } from "react";
import { navigate } from "gatsby";

class TermsGlobal extends Component {
  componentDidMount() {
    navigate("/terms-and-conditions/us/tou");
  }

  render() {
    return <div />;
  }
}

export default TermsGlobal;
